
import {Component, Vue} from 'vue-property-decorator';
import specList from './components/specList/index.vue';

@Component({
  name: 'specManage',
  components: {
    specList,
  },
})
export default class extends Vue {
  private productSpec = '规格'; //规格
  private specPrefix = `${this.productSpec}:`; //规格的前缀
  private productAttr = '属性'; //属性
  private attrPrefix = `${this.productAttr}:`; //属性的前缀
  private specPrompt = '不同规格对应不同价格、库存'; //规格提示
  private attrPrompt = '不同属性不影响价格、库存'; //属性提示
}
