
import {Component, Vue, Prop} from 'vue-property-decorator';
import {
  productLabels,
  shops,
  products,
  shopRequests,
} from '../../../../resources';
import {EJSON, ObjectId} from 'bson';
import GoodsListAdd from './add.vue';
import GoodsListEdit from './edit.vue';
import {Shop} from '@/externals/MaxCI-Shop-v1';
import {Form} from 'element-ui';
import {Product, ProductSKU, ProductSpec} from '@/externals/MaxCI-Product-v1';
import draggable from 'vuedraggable';
import {AxiosError} from 'axios';
import {saveAs} from 'file-saver';
import {ProductLabelSpec} from '@/externals/MaxCI-ProductLabel-v1';
import lodash from 'lodash';

interface ProductNew extends Product {
  stockFlag: boolean;
  stockText: string;
}
interface sortList {
  id?: ObjectId;
  name: string;
  values: Array<string>;
}
@Component({
  name: 'goodsSortList',
  components: {
    GoodsListAdd,
    GoodsListEdit,
    draggable,
  },
})
export default class extends Vue {
  @Prop()
  private type: string;
  private rerequiredCategory = '';
  private searchGoodsName = ''; //搜索商品名称
  private goodsOptions = {filter: '.goods_btn', preventOnFilter: false}; //商品拖拽组件配置
  private sortOptions = {filter: '.stopdrap', preventOnFilter: false}; //分类拖拽组件配置
  private operateSku: Array<ProductSKU> = []; //修改库存数据
  private dialogSpec = false; //规格设置弹窗
  private operateIndex = 0; //当前操作
  private dialogSortDel = false; //删除分类弹窗
  private dialogGoodsDel = false; //删除商品弹窗
  private operateId: ObjectId | null = null;
  private dialogSort = false; //分类弹窗
  private titleSort = ''; //分类 类型
  private formData = {name: '', requiredCategory: false}; //添加分类
  private rules = {
    name: [{required: true, message: '请输入分类名称', trigger: 'blur'}],
  };
  private submitFlag = false; //提交开关
  private saveLoading = false;
  private activeMenu = '全部商品'; //头部菜单
  private role = localStorage.getItem('role');
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private shopId = ObjectId.createFromHexString(this.$route.params.shopId);
  private shopInfo: Shop | null = null;
  private sortData: Array<sortList> = []; //分类列表数据
  private sortIndex = this.$route.query.sortIndex
    ? Number(this.$route.query.sortIndex)
    : 0; //当前选中分类
  private productData: Array<ProductNew> = []; //商品列表
  private allNum = 0; //全部商品数量
  private inServiceNum = 0; //下架商品数量
  private soldOutNum = 0; //售罄商品数量
  private noDeliveryNum = 0; //单点不送商品数量
  private isSearch = false; //是否在搜索
  private productLoading = false; //商品列表loading

  async created() {
    try {
      await this.updateSort();
      await this.checkShop();
      await this.checkProductNum();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  //查询商品数量
  private async checkProductNum() {
    const product = await products.find(stage =>
      stage
        .$match(match => {
          match(
            f => f('spec')('shopId'),
            e => e.$eq(this.shopId),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          );
          return match;
        })
        .$facet(facet =>
          facet('allNum', allNumStage =>
            allNumStage.$group(
              e => e.$literal(null),
              group => group('count', e => e.$sum(sum => sum.$literal(1))),
            ),
          )('inServiceNum', inServiceNumStage =>
            inServiceNumStage
              .$match(match =>
                match(
                  f => f('spec')('inService'),
                  e => e.$eq(false),
                ),
              )
              .$group(
                e => e.$literal(null),
                group => group('count', e => e.$sum(sum => sum.$literal(1))),
              ),
          )('soldOutNum', soldOutNumStage =>
            soldOutNumStage
              .$match(match =>
                match(
                  f => f('spec')('skus'),
                  e =>
                    e.$not(not =>
                      not.$elemMatch(q =>
                        q(
                          f => f('stock'),
                          e => e.$gt(0),
                        ),
                      ),
                    ),
                ),
              )
              .$group(
                e => e.$literal(null),
                group => group('count', e => e.$sum(sum => sum.$literal(1))),
              ),
          )('noDeliveryNum', noDeliveryNumStage =>
            noDeliveryNumStage
              .$match(match =>
                match(
                  f => f('spec')('requiredOther'),
                  e => e.$eq(true),
                ),
              )
              .$group(
                e => e.$literal(null),
                group => group('count', e => e.$sum(sum => sum.$literal(1))),
              ),
          ),
        ),
    );
    if (product.length > 0) {
      this.allNum =
        product[0].allNum.length > 0 ? product[0].allNum[0].count : 0;
      this.inServiceNum =
        product[0].inServiceNum.length > 0
          ? product[0].inServiceNum[0].count
          : 0;
      this.noDeliveryNum =
        product[0].noDeliveryNum.length > 0
          ? product[0].noDeliveryNum[0].count
          : 0;
      this.soldOutNum =
        product[0].soldOutNum.length > 0 ? product[0].soldOutNum[0].count : 0;
    }
  }
  //查询店铺信息
  private async checkShop() {
    this.shopInfo =
      (
        await shops.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$eq(this.shopId),
            ),
          ),
        )
      ).find(() => true) ?? null;
    this.rerequiredCategory = this.shopInfo?.spec.requiredCategory ?? '';
  }
  //查询分类
  private async updateSort() {
    try {
      this.sortData = [];
      this.productData = [];
      this.productLoading = true;
      const productSort = await productLabels.find(stage =>
        stage.$match(match =>
          match(
            f => f('spec')('name'),
            e => e.$eq('分类'),
          )(
            f => f('spec')('shopId'),
            e => e.$eq(this.shopId),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          ),
        ),
      );
      if (productSort.length > 0) {
        const productLabel = await productLabels.find(stage =>
          stage.$match(match =>
            match(
              f => f('spec')('name'),
              e => e.$regex(new RegExp('分类:.+:排序')),
            )(
              f => f('spec')('shopId'),
              e => e.$eq(this.shopId),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            ),
          ),
        );
        for (let item of productSort[0].spec.values) {
          for (let v of productLabel) {
            if (item === v.spec.name.split(':')[1]) {
              if (this.isSearch || this.activeMenu !== '全部商品') {
                const product = await products.find(stage =>
                  stage.$match(match => {
                    match(
                      f => f('_id'),
                      e =>
                        e.$in(
                          v.spec.values.map(v =>
                            ObjectId.createFromHexString(v),
                          ),
                        ),
                    )(
                      f => f('spec')('shopId'),
                      e => e.$eq(this.shopId),
                    )(
                      f => f('spec')('applicationId'),
                      e => e.$eq(this.applicationId),
                    );
                    if (this.searchGoodsName) {
                      match(
                        f => f('spec')('name'),
                        e => e.$regex(new RegExp(this.searchGoodsName)),
                      );
                    }
                    if (this.activeMenu === '已售罄') {
                      match(
                        f => f('spec')('skus'),
                        e =>
                          e.$not(not =>
                            not.$elemMatch(q =>
                              q(
                                f => f('stock'),
                                e => e.$gt(0),
                              ),
                            ),
                          ),
                      );
                    }
                    if (this.activeMenu === '已下架') {
                      match(
                        f => f('spec')('inService'),
                        e => e.$eq(false),
                      );
                    }
                    if (this.activeMenu === '单点不送') {
                      match(
                        f => f('spec')('requiredOther'),
                        e => e.$eq(true),
                      );
                    }
                    return match;
                  }),
                );
                if (product.length > 0) {
                  this.sortData.push({
                    id: v._id,
                    name: item,
                    values: product.map(v => v._id.toHexString()),
                  });
                }
              } else {
                this.sortData.push({
                  id: v._id,
                  name: item,
                  values: v.spec.values,
                });
              }
            }
          }
        }
        const values = [] as Array<string>;
        for (let item of this.sortData) {
          values.push(...item.values);
        }
        if (values.length >= 0) {
          const product = await products.find(stage =>
            stage.$match(match => {
              match(
                f => f('_id'),
                e => e.$nin(values.map(v => ObjectId.createFromHexString(v))),
              )(
                f => f('spec')('shopId'),
                e => e.$eq(this.shopId),
              )(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              );
              if (this.searchGoodsName) {
                match(
                  f => f('spec')('name'),
                  e => e.$regex(new RegExp(this.searchGoodsName)),
                );
              }
              if (this.activeMenu === '已售罄') {
                match(
                  f => f('spec')('skus'),
                  e =>
                    e.$not(not =>
                      not.$elemMatch(q =>
                        q(
                          f => f('stock'),
                          e => e.$gt(0),
                        ),
                      ),
                    ),
                );
              }
              if (this.activeMenu === '已下架') {
                match(
                  f => f('spec')('inService'),
                  e => e.$eq(false),
                );
              }
              if (this.activeMenu === '单点不送') {
                match(
                  f => f('spec')('requiredOther'),
                  e => e.$eq(true),
                );
              }
              return match;
            }),
          );
          if (product.length > 0) {
            this.sortData.push({
              name: '未分类',
              values: product.map(v => v._id.toHexString()),
            });
          }
        }
        console.log('this.sortData', this.sortData);
        this.productLoading = false;
        await this.updateProduct();
      }
    } finally {
      this.productLoading = false;
    }
  }
  //切换分类
  private changeSort(index: number) {
    this.sortIndex = index;
    this.productData = [];
    this.updateProduct();
  }
  //新增修改分类弹窗
  private operateSort(title: string, index?: number) {
    this.dialogSort = true;
    this.titleSort = title;
    if (title === '新增') {
      this.formData = {name: '', requiredCategory: false};
    } else {
      this.operateIndex = index ?? 0;
      this.formData = {
        name: this.sortData[index ?? 0].name,
        requiredCategory:
          this.sortData[index ?? 0].name ===
          this.shopInfo?.spec.requiredCategory
            ? true
            : false,
      };
    }
  }
  //新增修改分类保存
  private async sortSubmit() {
    (this.$refs.formData as Form).validate(async valid => {
      if (valid) {
        try {
          //新增修改分类
          if (this.titleSort === '新增') {
            this.submitFlag = true;
            const productLabel = await productLabels.update(
              filter =>
                filter(
                  f => f('spec')('name'),
                  e => e.$eq(`分类:${this.formData.name}:排序`),
                )(
                  f => f('spec')('shopId'),
                  e => e.$eq(this.shopId),
                )(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                ),
              update =>
                update
                  .$set(s => s(f => f('spec')('values'), []))
                  .$set(s => s(f => f('spec')('multiSelect'), false)),
              {
                upsert: true,
              },
            );
            // const productLabel = await productLabels.create([
            //   {
            //     spec: {
            //       name: `分类:${this.formData.name}:排序`,
            //       values: [],
            //       multiSelect: false,
            //       shopId: this.shopId,
            //       applicationId: this.applicationId,
            //     },
            //   },
            // ]);
            if (productLabel.length > 0) {
              productLabels
                .update(
                  filter =>
                    filter(
                      f => f('spec')('name'),
                      e => e.$eq('分类'),
                    )(
                      f => f('spec')('shopId'),
                      e => e.$eq(this.shopId),
                    )(
                      f => f('spec')('applicationId'),
                      e => e.$eq(this.applicationId),
                    ),
                  update =>
                    update
                      .$addToSet(add =>
                        add(f => f('spec')('values'), this.formData.name),
                      )
                      .$set(s => s(f => f('spec')('multiSelect'), false)),
                  {
                    upsert: true,
                  },
                )
                .then(res => {
                  if (res.length > 0) {
                    this.dialogSort = false;
                    this.$message.success('保存成功');
                    this.sortData.push({
                      id: productLabel[0]._id,
                      name: this.formData.name,
                      values: [],
                    });
                  }
                });
            }
          } else {
            if (this.formData.name !== this.sortData[this.operateIndex].name) {
              const sorts = this.sortData.map((v, index) => {
                if (index !== this.operateIndex) {
                  return v.name;
                }
              });
              if (sorts.indexOf(this.formData.name) > -1) {
                this.$message.error('分类名称不能重复');
                return;
              }
              const oldLabelName = this.sortData[this.operateIndex].name;
              this.submitFlag = true;
              await productLabels.update(
                filter =>
                  filter(
                    f => f('spec')('name'),
                    e => e.$eq('分类'),
                  )(
                    f => f('spec')('shopId'),
                    e => e.$eq(this.shopId),
                  )(
                    f => f('spec')('applicationId'),
                    e => e.$eq(this.applicationId),
                  ),
                update =>
                  update.$set(s =>
                    s(
                      f => f('spec')('values')(this.operateIndex),
                      this.formData.name,
                    ),
                  ),
              );
              await productLabels.update(
                filter =>
                  filter(
                    f => f('spec')('name'),
                    e => e.$eq('分类:' + oldLabelName + ':排序'),
                  )(
                    f => f('spec')('shopId'),
                    e => e.$eq(this.shopId),
                  )(
                    f => f('spec')('applicationId'),
                    e => e.$eq(this.applicationId),
                  ),
                update =>
                  update.$set(s =>
                    s(
                      f => f('spec')('name'),
                      '分类:' + this.formData.name + ':排序',
                    ),
                  ),
              );
              await products.update(
                filter =>
                  filter(
                    f => f('spec')('labels'),
                    e => e.$in(['分类:' + oldLabelName]),
                  )(
                    f => f('spec')('shopId'),
                    e => e.$eq(this.shopId),
                  )(
                    f => f('spec')('applicationId'),
                    e => e.$eq(this.applicationId),
                  ),
                update =>
                  update.$set(s =>
                    s(
                      f => f('spec')('labels')('$'),
                      '分类:' + this.formData.name,
                    ),
                  ),
              );
              this.$message.success('保存成功');
              this.sortData[this.operateIndex].name = this.formData.name;
            }
          }

          //修改必点分类
          if (
            !this.rerequiredCategory ||
            (this.titleSort === '修改' &&
              this.sortData[this.operateIndex].name === this.rerequiredCategory)
          ) {
            if (this.role === '店铺') {
              await shopRequests.create([
                {
                  spec: {
                    requiredCategory: this.formData.requiredCategory
                      ? this.formData.name
                      : '',
                    shopId: this.shopId,
                    applicationId: this.applicationId,
                  },
                },
              ]);
            } else {
              await shops.update(
                filter =>
                  filter(
                    f => f('_id'),
                    e => e.$eq(this.shopId),
                  )(
                    f => f('spec')('applicationId'),
                    e => e.$eq(this.applicationId),
                  ),
                update =>
                  update.$set(s =>
                    s(
                      f => f('spec')('requiredCategory'),
                      this.formData.requiredCategory ? this.formData.name : '',
                    ),
                  ),
              );
            }
            await this.checkShop();
            this.dialogSort = false;
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          if (e.isAxiosError) {
            const axiosError = e as AxiosError;
            if (
              axiosError.response?.status === 500 &&
              (axiosError.response.data.name === 'MongoError' ||
                axiosError.response.data.name === 'Error') &&
              axiosError.response.data.message.indexOf('E11000') > -1
            ) {
              this.$message.error('分类不能重复');
            }
          }
        } finally {
          this.submitFlag = false;
        }
      } else {
        return false;
      }
    });
  }
  //删除分类提示
  private delSortPopUps(index: number) {
    this.operateIndex = index;
    this.dialogSortDel = true;
    console.log(this.sortData[this.operateIndex]);
  }
  //删除分类保存
  private async delSortSumbit() {
    try {
      const sortName = this.sortData[this.operateIndex].name;
      const productLabelId = this.sortData[this.operateIndex].id;
      if (productLabelId) {
        if (sortName === this.rerequiredCategory) {
          if (this.role === '店铺') {
            await shopRequests.create([
              {
                spec: {
                  requiredCategory: '',
                  shopId: this.shopId,
                  applicationId: this.applicationId,
                },
              },
            ]);
          } else {
            await shops.update(
              filter =>
                filter(
                  f => f('_id'),
                  e => e.$eq(this.shopId),
                )(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                ),
              update =>
                update.$set(s => s(f => f('spec')('requiredCategory'), '')),
            );
          }
          await this.checkShop();
        }
        const productLabel = await productLabels.update(
          filter =>
            filter(
              f => f('spec')('name'),
              e => e.$eq('分类'),
            )(
              f => f('spec')('shopId'),
              e => e.$eq(this.shopId),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            ),
          update =>
            update.$pull(p => p(f => f('spec')('values'), 'value', sortName)),
        );
        if (productLabel.length > 0) {
          productLabels
            .delete(filter =>
              filter(
                f => f('_id'),
                e => e.$eq(productLabelId),
              )(
                f => f('spec')('shopId'),
                e => e.$eq(this.shopId),
              )(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              ),
            )
            .then(res => {
              if (res.length > 0) {
                this.$message.success('删除成功');
                this.dialogSortDel = false;
                if (this.operateIndex === this.sortIndex) {
                  this.sortIndex = 0;
                  this.updateSort();
                } else {
                  this.sortData.splice(this.operateIndex, 1);
                }
              }
            });
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //分类排序
  private async changeSortPosition(e: {moved: {newIndex: number}}) {
    const values = this.sortData.map(v => {
      return v.name;
    });
    await productLabels.update(
      filter =>
        filter(
          f => f('spec')('name'),
          e => e.$eq('分类'),
        )(
          f => f('spec')('shopId'),
          e => e.$eq(this.shopId),
        )(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        ),
      update => update.$set(s => s(f => f('spec')('values'), values)),
    );
    this.sortIndex = e.moved.newIndex;
    this.productData = [];
    await this.updateProduct();
  }
  //搜索商品
  private async searchGoods() {
    this.sortIndex = 0;
    if (this.searchGoodsName) {
      this.isSearch = true;
    } else {
      this.isSearch = false;
    }
    await this.updateSort();
  }
  //清除搜索
  private clearSearch() {
    this.sortIndex = 0;
    this.isSearch = false;
    this.updateSort();
  }
  //查询商品
  private async updateProduct() {
    if (this.sortData.length > 0) {
      if (this.sortIndex > this.sortData.length - 1) {
        this.sortIndex = 0;
        this.$router.push({
          query: {
            menu: this.$route.query.menu,
            type: 'list',
            sortIndex: '0',
          },
        });
      }
      const values = this.sortData[this.sortIndex].values;
      console.log(values);
      const len = Math.ceil(values.length / 10);
      for (let i = 0; i < len; i++) {
        let num = 0;
        if (i + 1 <= len) {
          num = 10;
        } else {
          num = values.length % 10;
        }
        const start = i * 10;
        const end = start + num;
        const ids = values.slice(start, end);
        const product = await products.find(stage =>
          stage.$match(match => {
            match(
              f => f('_id'),
              e => e.$in(ids.map(v => ObjectId.createFromHexString(v))),
            )(
              f => f('spec')('shopId'),
              e => e.$eq(this.shopId),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            );
            if (this.searchGoodsName) {
              match(
                f => f('spec')('name'),
                e => e.$regex(new RegExp(this.searchGoodsName)),
              );
            }
            if (this.activeMenu === '已售罄') {
              match(
                f => f('spec')('skus'),
                e =>
                  e.$not(not =>
                    not.$elemMatch(q =>
                      q(
                        f => f('stock'),
                        e => e.$gt(0),
                      ),
                    ),
                  ),
              );
            }
            if (this.activeMenu === '已下架') {
              match(
                f => f('spec')('inService'),
                e => e.$eq(false),
              );
            }
            if (this.activeMenu === '单点不送') {
              match(
                f => f('spec')('requiredOther'),
                e => e.$eq(true),
              );
            }
            return match;
          }),
        );
        if (this.activeMenu === '全部商品') {
          let delProduct = [] as Array<string>; //需要从分类排序中删除的商品id

          //没有查询到商品的Id删除
          const productIds = product.map(v => v._id.toHexString());
          delProduct = lodash.difference(ids, productIds);

          //判断查询的商品雨当前分类是否一致
          for (let item of ids) {
            for (let v of product) {
              if (
                v.spec.labels.indexOf(
                  `分类:${this.sortData[this.sortIndex].name}`,
                ) > -1 ||
                this.sortData[this.sortIndex].name === '未分类'
              ) {
                if (item === v._id.toHexString()) {
                  this.productData.push({
                    ...v,
                    stockFlag: false,
                    stockText: '',
                  });
                }
              } else {
                delProduct.push(v._id.toHexString());
              }
            }
          }
          delProduct = Array.from(new Set(delProduct));
          if (delProduct.length > 0) {
            await productLabels.update(
              filter =>
                filter(
                  f => f('spec')('name'),
                  e => e.$eq(`分类:${this.sortData[this.sortIndex].name}:排序`),
                )(
                  f => f('spec')('shopId'),
                  e => e.$eq(this.shopId),
                )(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                ),
              update =>
                update.$pullAll(p => p(f => f('spec')('values'), delProduct)),
            );
            this.sortData[this.sortIndex].values = this.sortData[
              this.sortIndex
            ].values.filter(v => {
              return delProduct.indexOf(v) === -1;
            });
          }
        } else {
          for (let item of ids) {
            for (let v of product) {
              if (
                v.spec.labels.indexOf(
                  `分类:${this.sortData[this.sortIndex].name}`,
                ) > -1 ||
                this.sortData[this.sortIndex].name === '未分类'
              ) {
                if (item === v._id.toHexString()) {
                  this.productData.push({
                    ...v,
                    stockFlag: false,
                    stockText: '',
                  });
                }
              }
            }
          }
        }
      }
      console.log('this.productData', this.productData);
    }
  }
  //修改单规格库存
  private async changeStock(goodsIndex: number) {
    let goods = this.productData[goodsIndex];
    if (goods) {
      goods.stockFlag = false;
    }
    const product = await products.update(
      filter =>
        filter(
          f => f('_id'),
          e => e.$eq(goods._id),
        )(
          f => f('spec')('shopId'),
          e => e.$eq(this.shopId),
        )(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        ),
      update =>
        update.$set(s =>
          s(
            f => f('spec')('skus')(0)('stock'),
            Number(this.productData[goodsIndex].stockText),
          ),
        ),
    );
    if (product.length > 0) {
      this.productData = [];
      this.updateProduct();
    }
  }
  //修改库存
  private stockBtn(goodsIndex: number) {
    if (this.productData[goodsIndex].spec.skus.length === 1) {
      document.getElementById('stockinput' + goodsIndex)?.focus();
      let goods = this.productData[goodsIndex];
      if (goods) {
        goods.stockFlag = true;
      }
    } else {
      this.operateId = this.productData[goodsIndex]._id;
      this.operateSku =
        this.productData[goodsIndex].spec.skus.map(v => {
          return {
            name: v.name,
            price: v.price,
            stock: v.stock,
          };
        }) ?? [];
      this.dialogSpec = true;
    }
  }
  //修改多规格规格保存
  private specSubmit() {
    let stockFlag = true;
    const skus = [] as Array<ProductSKU>;
    this.operateSku.forEach(item => {
      if (
        item.stock &&
        !/^([0]|[1-9][0-9]*)$/.test((item.stock ? item.stock : '').toString())
      ) {
        stockFlag = false;
      }
      let stock = 0;
      if (item.stock) {
        stock = Number(item.stock);
      }
      skus.push({
        name: item.name,
        price: item.price,
        stock: stock,
      });
    });
    if (!stockFlag) {
      this.$message.error('规格库存必须为正整数');
      return;
    }
    products
      .update(
        filter =>
          filter(
            f => f('_id'),
            e => e.$eq(this.operateId as ObjectId),
          )(
            f => f('spec')('shopId'),
            e => e.$eq(this.shopId),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          ),
        update =>
          update.$set(s =>
            s(f => f('spec')('skus'), skus as [ProductSKU, ...ProductSKU[]]),
          ),
      )
      .then(res => {
        if (res.length > 0) {
          this.updateSort();
          this.$message.success('保存成功');
          this.dialogSpec = false;
        }
      });
  }
  //商品上下架
  private async changeStatus(id: ObjectId, status: boolean, index: number) {
    const product = await products.update(
      filter =>
        filter(
          f => f('_id'),
          e => e.$eq(id),
        )(
          f => f('spec')('shopId'),
          e => e.$eq(this.shopId),
        )(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        ),
      update => update.$set(s => s(f => f('spec')('inService'), !status)),
    );
    if (product.length > 0) {
      this.$message.success(status ? '下架成功' : '上架成功');
      this.productData[index].spec.inService = !status;
    }
  }
  //删除商品提示
  private delGoodsPopUps(id: ObjectId, goodsIndex: number) {
    this.operateId = id;
    this.dialogGoodsDel = true;
    this.operateIndex = goodsIndex;
  }
  //删除商品保存
  private async delGoodsSumbit() {
    const product = await products.delete(filter =>
      filter(
        f => f('_id'),
        e => e.$eq(this.operateId as ObjectId),
      )(
        f => f('spec')('shopId'),
        e => e.$eq(this.shopId),
      )(
        f => f('spec')('applicationId'),
        e => e.$eq(this.applicationId),
      ),
    );

    if (product.length > 0) {
      productLabels
        .update(
          filter =>
            filter(
              f => f('spec')('name'),
              e =>
                e.$eq('分类:' + this.sortData[this.sortIndex].name + ':排序'),
            )(
              f => f('spec')('shopId'),
              e => e.$eq(this.shopId),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            ),
          update =>
            update.$pull(p =>
              p(
                f => f('spec')('values'),
                'value',
                (this.operateId as ObjectId).toHexString(),
              ),
            ),
        )
        .then(res => {
          if (res.length > 0) {
            this.$message.success('删除成功');
            this.dialogGoodsDel = false;
            this.productData.splice(this.operateIndex, 1);
          }
        });
    }
  }
  //商品计算库存
  private getStock(list: Array<ProductSKU>) {
    let len = 0;
    list.forEach(item => {
      len = len + Number(item.stock);
    });
    return len;
  }
  //计算商品价格
  private getPrice(list: Array<ProductSKU>) {
    const priceList = [] as Array<number>;
    list.forEach(item => {
      if (item.price) {
        priceList.push(item.price);
      }
    });
    if (priceList.length > 0) {
      if (priceList.length === 1) {
        return Math.min(...priceList) / 100;
      } else {
        return Math.min(...priceList) / 100 + '起';
      }
    } else {
      return 0;
    }
  }
  //商品排序
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async changeGoodsPosition(e: any) {
    //修改排序
    const values = [] as Array<string>;
    this.productData.forEach(item => {
      values.push(item._id.toHexString());
    });
    productLabels.update(
      filter =>
        filter(
          f => f('spec')('name'),
          e => e.$eq(`分类:${this.sortData[this.sortIndex].name}:排序`),
        )(
          f => f('spec')('shopId'),
          e => e.$eq(this.shopId),
        )(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        ),
      update => update.$set(s => s(f => f('spec')('values'), values)),
    );
    if (e.added) {
      const labels = e.added.element.goods.spec.labels as Array<string>;
      let label = '';
      labels.forEach(item => {
        if (item.indexOf('分类:') !== -1) {
          label = item;
        }
      });
      const id = e.added.element.goods._id as ObjectId;
      products.update(
        filter =>
          filter(
            f => f('_id'),
            e => e.$eq(id),
          )(
            f => f('spec')('shopId'),
            e => e.$eq(this.shopId),
          )(
            f => f('spec')('labels'),
            e => e.$in([label]),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          ),
        update =>
          update.$set(s =>
            s(
              f => f('spec')('labels')('$'),
              `分类:${this.sortData[this.sortIndex].name}`,
            ),
          ),
      );
    }
  }
  //导出
  private async exportProduct() {
    this.saveLoading = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let goods: any = [];
    try {
      const count = await products.find(stage =>
        stage
          .$match(match =>
            match(
              f => f('spec')('shopId'),
              e => e.$eq(this.shopId),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            ),
          )
          .$count('count'),
      );
      if (count.length === 0 || count[0].count === 0) {
        this.$message.error('暂无商品');
        return;
      }
      let i = 0;
      while (i < Math.ceil(count[0].count / 100)) {
        i++;
        const list = await products.find(stage =>
          stage
            .$match(match =>
              match(
                f => f('spec')('shopId'),
                e => e.$eq(this.shopId),
              )(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              ),
            )
            .$skip((i - 1) * 100)
            .$limit(100),
        );
        console.log(list);
        for (let item of list) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const good = item.spec as any;
          delete good.applicationId;
          delete good.shopId;
          delete good.sales;
          delete good.virtualSales;
          delete good.score;
          goods.push({
            spec: good,
          });
        }
      }
      const productLabel = await productLabels.find(stage =>
        stage.$match(match =>
          match(
            f => f('spec')('shopId'),
            e => e.$eq(this.shopId),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          ),
        ),
      );

      const labels = productLabel
        .map(v => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const label = v.spec as any;
          delete label.applicationId;
          delete label.shopId;
          if (
            v.spec.name.indexOf('规格') === -1 &&
            v.spec.name.indexOf('属性') === -1
          ) {
            return {
              spec: {
                name: v.spec.name,
                multiSelect: v.spec.multiSelect,
                values:
                  v.spec.name.indexOf(':排序') === -1 ? v.spec.values : [],
              },
            };
          }
        })
        .filter((v): v is NonNullable<typeof v> => !!v);
      const blob = new Blob([EJSON.stringify({goods: goods, labels: labels})], {
        type: 'text/plain;charset=utf-8',
      });
      saveAs(blob, '商品.json');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.saveLoading = false;
    }
  }
  //导入
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async importProduct(file: any) {
    try {
      this.saveLoading = true;
      //新增商品
      let goods = JSON.parse((await this.getImportData(file)) as string)
        .goods as Array<{
        spec: ProductSpec;
      }>;
      goods = goods.map(v => {
        v.spec.applicationId = this.applicationId;
        v.spec.shopId = this.shopId;
        return {
          ...v,
        };
      });
      const productList = [];
      for (let item of goods) {
        try {
          const product = await products.update(
            filter =>
              filter(
                f => f('spec')('name'),
                e => e.$eq(item.spec.name),
              )(
                f => f('spec')('shopId'),
                e => e.$eq(this.shopId),
              )(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              ),
            update => {
              update.$set(s => s(f => f('spec'), item.spec));
              return update;
            },
            {
              upsert: true,
            },
          );
          if (product.length > 0) {
            productList.push({
              _id: product[0]._id,
              name: item.spec.name,
              labels: item.spec.labels,
            });
          }
        } catch (e) {
          console.log(e);
        }
      }
      //新增分类
      let labels = JSON.parse((await this.getImportData(file)) as string)
        .labels as Array<{
        spec: ProductLabelSpec;
      }>;
      labels = labels.map(v => {
        v.spec.applicationId = this.applicationId;
        v.spec.shopId = this.shopId;
        return {
          ...v,
        };
      });
      for (let item of labels) {
        const labels = [] as Array<string>;
        if (item.spec.name.indexOf(':排序') > -1) {
          const name = item.spec.name.substring(0, item.spec.name.length - 3);
          for (let v of productList) {
            if (v.labels.indexOf(name) > -1) {
              labels.push(v._id.toHexString());
            }
          }
        }
        try {
          await productLabels.update(
            filter =>
              filter(
                f => f('spec')('name'),
                e => e.$eq(item.spec.name),
              )(
                f => f('spec')('shopId'),
                e => e.$eq(this.shopId),
              )(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              ),
            update => {
              update.$addToSet(s =>
                s(f => f('spec')('values'), {
                  $each:
                    item.spec.name.indexOf(':排序') === -1
                      ? item.spec.values
                      : labels,
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } as any),
              );
              update.$set(s =>
                s(f => f('spec')('multiSelect'), item.spec.multiSelect),
              );
              return update;
            },
            {
              upsert: true,
            },
          );
        } catch (e) {
          console.log(e);
        }
      }
      this.updateSort();
    } finally {
      this.saveLoading = false;
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async getImportData(file: any) {
    return new Promise(function (resolve) {
      const reader = new FileReader();
      reader.readAsText(file.raw);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      reader.onload = () => {
        if (reader.result) {
          resolve(reader.result);
        }
      };
    });
  }
  //新增
  private addBtn() {
    this.$emit('changeType', 'add');
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'add',
        sortName: this.sortData[this.sortIndex].name,
        sortIndex: this.sortIndex.toString(),
      },
    });
  }
  //编辑
  private editBtn(id: ObjectId) {
    this.operateId = id;
    this.$emit('changeType', 'edit');
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'edit',
        sortName: this.sortData[this.sortIndex].name,
        detailId: id.toHexString(),
        sortIndex: this.sortIndex.toString(),
      },
    });
  }
  //返回列表
  private back(index: string) {
    this.$emit('changeType', 'list');
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'list',
        sortIndex: index,
      },
    });
    this.updateSort();
  }
}
