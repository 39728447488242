
import {Component, Vue, Prop} from 'vue-property-decorator';
import {productLabels} from '../../../../../../resources';
import {ObjectId} from 'bson';
import draggable from 'vuedraggable';
import {AxiosError} from 'axios';
import inputClose from '@/components/inputClose/index.vue';

interface List {
  id: string;
  _id: ObjectId | null;
  name: string;
  values: Array<string>;
  multiSelect: boolean;
  value: string;
  verification: boolean;
}

@Component({
  name: 'specManage',
  components: {
    draggable,
    inputClose,
  },
})
export default class extends Vue {
  @Prop()
  private name: string;
  @Prop()
  private prefix: string;
  @Prop()
  private prompt: string;

  private operateId: ObjectId | null = null;
  private operateName = '';
  private dialogDel = false; //删除弹窗
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private shopId = ObjectId.createFromHexString(this.$route.params.shopId);
  private list: Array<List> = []; //列表数据
  private oldList: Array<List> = []; //旧列表数据

  async created() {
    this.updateList();
  }
  //列表查询
  private async updateList() {
    const sortList = await productLabels.find(stage =>
      stage.$match(match =>
        match(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        )(
          f => f('spec')('shopId'),
          e => e.$eq(this.shopId),
        )(
          f => f('spec')('name'),
          e => e.$eq(this.name),
        ),
      ),
    );
    if (sortList.length > 0) {
      const list = sortList[0].spec.values.map(v => {
        return {
          id: v,
          _id: null,
          name: '',
          values: [],
          multiSelect: false,
          value: '',
          verification: false,
        };
      }) as Array<List>;
      const specList = await productLabels.find(stage =>
        stage.$match(match =>
          match(
            f => f('_id'),
            e =>
              e.$in(
                sortList[0].spec.values.map(v =>
                  ObjectId.createFromHexString(v),
                ),
              ),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          )(
            f => f('spec')('shopId'),
            e => e.$eq(this.shopId),
          ),
        ),
      );
      list.forEach(item => {
        specList.forEach(specItem => {
          if (item.id === specItem._id.toHexString()) {
            item._id = specItem._id;
            item.name = specItem.spec.name.replace(this.prefix, '');
            item.values = specItem.spec.values.map(v => v);
            item.multiSelect = specItem.spec.multiSelect;
          }
        });
      });
      this.list = list;
      this.oldList = list;
    }
  }
  //列表排序
  private changeSpecPosition() {
    const values = this.list.map(v => v.id);
    productLabels.update(
      filter =>
        filter(
          f => f('spec')('name'),
          e => e.$eq(this.name),
        )(
          f => f('spec')('shopId'),
          e => e.$eq(this.shopId),
        )(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        ),
      update => update.$set(s => s(f => f('spec')('values'), values)),
    );
  }
  //值排序
  private changePosition(index: number) {
    productLabels.update(
      filter =>
        filter(
          f => f('_id'),
          e => e.$eq(this.list[index]._id as ObjectId),
        )(
          f => f('spec')('shopId'),
          e => e.$eq(this.shopId),
        )(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        ),
      update =>
        update.$set(s => s(f => f('spec')('values'), this.list[index].values)),
    );
  }
  //新增 值
  private async addValue(index: number) {
    let num = 0;
    this.list[index].values.forEach(item => {
      if (item === this.list[index].value) {
        num++;
      }
    });
    this.list[index].verification = false;
    if (this.list[index].value) {
      if (num === 0) {
        const productLabel = await productLabels.update(
          filter =>
            filter(
              f => f('_id'),
              e => e.$eq(this.list[index]._id as ObjectId),
            )(
              f => f('spec')('shopId'),
              e => e.$eq(this.shopId),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            ),
          update =>
            update.$push(p =>
              p(f => f('spec')('values'), this.list[index].value),
            ),
        );
        if (productLabel.length > 0) {
          this.updateList();
        }
      } else {
        this.list[index].verification = true;
      }
    }
  }
  //添加
  private addBtn() {
    this.list.unshift({
      id: '',
      _id: null,
      name: '',
      values: [],
      multiSelect: false,
      value: '',
      verification: false,
    });
  }
  //修改
  private async changeSpec(index: number) {
    if (this.list[index]._id) {
      //修改
      if (this.list[index].name) {
        if (this.list[index].name !== this.oldList[index].name) {
          await productLabels.update(
            filter =>
              filter(
                f => f('_id'),
                e => e.$eq(this.list[index]._id as ObjectId),
              )(
                f => f('spec')('shopId'),
                e => e.$eq(this.shopId),
              )(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              ),
            update =>
              update.$set(s =>
                s(f => f('spec')('name'), this.prefix + this.list[index].name),
              ),
          );
        }
      } else {
        this.list[index].name = this.oldList[index].name;
      }
    } else {
      //新增
      if (this.list[index].name) {
        try {
          const productLabel = await productLabels.create([
            {
              spec: {
                name: this.prefix + this.list[index].name,
                values: [],
                multiSelect: false,
                applicationId: this.applicationId,
                shopId: this.shopId,
              },
            },
          ]);
          if (productLabel.length > 0) {
            productLabels
              .update(
                filter =>
                  filter(
                    f => f('spec')('name'),
                    e => e.$eq(this.name),
                  )(
                    f => f('spec')('shopId'),
                    e => e.$eq(this.shopId),
                  )(
                    f => f('spec')('applicationId'),
                    e => e.$eq(this.applicationId),
                  ),
                update =>
                  update
                    .$push(p =>
                      p(f => f('spec')('values'), {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        $each: [productLabel[0]._id.toHexString()] as any,
                        $position: 0,
                      }),
                    )
                    .$set(s => s(f => f('spec')('multiSelect'), false)),
                {
                  upsert: true,
                },
              )
              .then(res => {
                if (res.length > 0) {
                  this.updateList();
                }
              });
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          if (e.isAxiosError) {
            const axiosError = e as AxiosError;
            if (
              axiosError.response?.status === 500 &&
              (axiosError.response.data.name === 'MongoError' ||
                axiosError.response.data.name === 'Error') &&
              axiosError.response.data.message.indexOf('E11000') > -1
            ) {
              this.$message.error(this.name + '名称不能重复');
            }
          }
        }
      } else {
        this.list.splice(index, 1);
      }
    }
  }
  //删除提示
  private delPopUps(id: ObjectId) {
    this.operateId = id;
    this.dialogDel = true;
  }
  //删除保存
  private async delSumbit() {
    try {
      const productLabel = await productLabels.delete(filter =>
        filter(
          f => f('_id'),
          e => e.$eq(this.operateId as ObjectId),
        )(
          f => f('spec')('shopId'),
          e => e.$eq(this.shopId),
        )(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        ),
      );
      if (productLabel.length > 0) {
        productLabels
          .update(
            filter =>
              filter(
                f => f('spec')('name'),
                e => e.$eq(this.name),
              )(
                f => f('spec')('shopId'),
                e => e.$eq(this.shopId),
              )(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              ),
            update =>
              update.$pull(p =>
                p(
                  f => f('spec')('values'),
                  'value',
                  (this.operateId as ObjectId).toHexString(),
                ),
              ),
            {
              upsert: true,
            },
          )
          .then(res => {
            if (res.length > 0) {
              this.$message.success('删除成功');
              this.dialogDel = false;
              this.updateList();
            }
          });
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
}
