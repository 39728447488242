
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import inputClose from '@/components/inputClose/index.vue';
import draggable from 'vuedraggable';
import {ProductAttribute} from '@/externals/MaxCI-Product-v1';
import {productLabels} from '../../../../resources';
import {ObjectId} from 'bson';

interface ListSpec {
  name: string;
  values: Array<string>;
  value?: string;
  verification?: boolean;
  focus?: boolean;
}
interface CommonList {
  id: string;
  name: string;
  values: Array<string>;
}
@Component({
  name: 'attrComp',
  components: {
    inputClose,
    draggable,
  },
})
export default class extends Vue {
  @Prop()
  private type: string;
  @Prop()
  private data: Array<ProductAttribute>;
  private dialogDel = false; //删除弹窗
  private opreateIndex = 0;
  private list = [] as Array<ListSpec>;
  private oldList = [] as Array<ListSpec>;
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private commonList: Array<CommonList> = [];
  private shopId = ObjectId.createFromHexString(this.$route.params.shopId);

  created() {
    this.list = this.data.map(v => {
      return {
        name: v.name,
        values: v.values,
        text: '',
        verification: false,
        focus: false,
      };
    });
    this.oldList = this.data.map(v => {
      return {
        name: v.name,
        values: v.values,
      };
    });
    this.updateCommonList();
  }
  @Watch('data')
  private changeData() {
    this.list = this.data.map(v => {
      return {
        name: v.name,
        values: v.values,
        text: '',
        verification: false,
        focus: false,
      };
    });
    this.oldList = this.data.map(v => {
      return {
        name: v.name,
        values: v.values,
      };
    });
  }
  //选择规格属性
  private changeCommon(index: number, commonIndex: number) {
    this.list[index].focus = false;
    this.list[index].name = this.getCommonName(
      this.commonList[commonIndex].name,
    );
    this.list[index].values = this.commonList[commonIndex].values.map(v => v);
    this.updateValue(index);
    this.list[index].focus = false;
  }
  //处理规格属性名称
  private getCommonName(name: string) {
    return name.replace(`${this.type}:`, '');
  }
  //常用列表
  private async updateCommonList() {
    const sortList = await productLabels.find(stage =>
      stage.$match(match =>
        match(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        )(
          f => f('spec')('shopId'),
          e => e.$eq(this.shopId),
        )(
          f => f('spec')('name'),
          e => e.$eq(this.type),
        ),
      ),
    );
    if (sortList.length > 0) {
      const list = sortList[0].spec.values.map(v => {
        return {
          id: v,
          name: '',
          values: [],
        };
      }) as Array<CommonList>;
      const specList = await productLabels.find(stage =>
        stage.$match(match =>
          match(
            f => f('_id'),
            e =>
              e.$in(
                sortList[0].spec.values.map(v =>
                  ObjectId.createFromHexString(v),
                ),
              ),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          )(
            f => f('spec')('shopId'),
            e => e.$eq(this.shopId),
          ),
        ),
      );
      list.forEach(item => {
        specList.forEach(specItem => {
          if (item.id === specItem._id.toHexString()) {
            item.name = specItem.spec.name;
            item.values = specItem.spec.values;
          }
        });
      });
      this.commonList = list;
    }
  }
  //设为常用
  private async setCommon(index: number) {
    const goodsLabels = await productLabels.update(
      filter =>
        filter(
          f => f('spec')('name'),
          e => e.$eq(this.type + ':' + this.list[index].name),
        )(
          f => f('spec')('shopId'),
          e => e.$eq(this.shopId),
        )(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        ),
      update =>
        update.$set(s =>
          s(f => f('spec')('values'), this.list[index].values)(
            f => f('spec')('multiSelect'),
            false,
          ),
        ),
      {
        upsert: true,
      },
    );
    if (goodsLabels.length > 0) {
      productLabels
        .update(
          filter =>
            filter(
              f => f('spec')('name'),
              e => e.$eq(this.type),
            )(
              f => f('spec')('shopId'),
              e => e.$eq(this.shopId),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            ),
          update =>
            update
              .$addToSet(add =>
                add(f => f('spec')('values'), goodsLabels[0]._id.toHexString()),
              )
              .$set(s => s(f => f('spec')('multiSelect'), false)),
          {
            upsert: true,
          },
        )
        .then(res => {
          if (res.length > 0) {
            this.$message.success('设置成功');
          }
        });
    }
  }
  //添加修改名称
  private changeName(index: number) {
    if (this.list[index].name) {
      this.updateValue(index);
    } else {
      if (this.oldList.length > 0) {
        this.list[index].name = this.oldList[index].name;
      }
    }
  }
  //添加属性值
  private addValue(index: number) {
    if (this.list[index].value) {
      this.list[index].values.push(this.list[index].value ?? '');
      this.list[index].value = '';
      this.updateValue(index);
    }

    // if (getStrLength(this.list[index].value ?? '') > 40) {
    //   this.list[index].verification = true;
    // } else {
    //   let num = 0;
    //   this.list[index].values.forEach(item => {
    //     if (item === this.list[index].value) {
    //       num++;
    //     }
    //   });
    //   this.list[index].verification = false;
    //   if (this.list[index].value) {
    //     if (num === 0) {
    //       this.list[index].values.push(this.list[index].value ?? '');
    //       this.list[index].value = '';
    //       this.updateValue(index);
    //     } else {
    //       this.list[index].verification = true;
    //     }
    //   }
    // }
  }
  //更新旧值
  private updateValue(index: number) {
    this.oldList = this.list.map(v => {
      return {
        name: v.name,
        values: v.values.map(v => v),
      };
    });
    if (this.list[index].values.length > 0) {
      this.$emit('changeSpec', this.type, this.list);
    }
  }
  //属性值换位置
  private changePositionValue(index: number) {
    this.updateValue(index);
  }
  //属性值换位置
  private changePosition() {
    this.$emit('changeSpec', this.type, this.list);
  }
  //修改值
  private changeValue(index: number, valueIndex: number, value: string) {
    this.list[index].values[valueIndex] = value;
    this.oldList[index].values[valueIndex] = value;
    this.$emit('changeSpec', this.type, this.list);
  }
  //删除值
  private delValue(index: number, valueIndex: number) {
    this.list[index].values.splice(valueIndex, 1);
    // this.oldList[index].values.splice(valueIndex, 1);
    this.$emit('changeSpec', this.type, this.list);
  }
  //删除提示
  private delPopUps(index: number) {
    this.opreateIndex = index;
    this.dialogDel = true;
  }
  //删除保存
  private async delSumbit() {
    this.list.splice(this.opreateIndex, 1);
    // this.oldList.splice(this.opreateIndex, 1);
    this.$emit('changeSpec', this.type, this.list);
    this.dialogDel = false;
  }
  //添加属性
  private addBtn() {
    this.list.push({
      name: '',
      value: '',
      values: [],
      verification: false,
      focus: false,
    });
  }
}
