
/* eslint-disable @typescript-eslint/no-unused-vars */
import {Component, Vue, Watch} from 'vue-property-decorator';
import Submenu from '@/components/Submenu/index.vue';
import GoodsList from './components/goodsList/index.vue';
import CommonSpec from './components/commonSpec/index.vue';
import {shops} from '../../resources';
import {ObjectId} from 'bson';
import {Shop} from '@/externals/MaxCI-Shop-v1';

@Component({
  name: 'goods',
  components: {
    Submenu,
    GoodsList,
    CommonSpec,
  },
})
export default class extends Vue {
  private type = 'list';
  private menu = '';
  private submenu = [
    {
      top: '',
      list: [
        {name: '商品列表', active: true},
        {name: '规格属性库', active: false},
      ],
    },
  ];
  private shopId = ObjectId.createFromHexString(this.$route.params.shopId);
  private shopInfo: Shop | null = null;
  created() {
    this.$store.state.applicationId = this.$route.params.applicationId;
    this.menu = this.$route.query.menu
      ? (this.$route.query.menu as string)
      : '商品列表';
    this.type = this.$route.query.type
      ? (this.$route.query.type as string)
      : 'list';
    this.checkShop();
  }
  @Watch('$route', {deep: true})
  routechange() {
    this.$store.state.applicationId = this.$route.params.applicationId;
  }
  private async checkShop() {
    this.shopInfo =
      (
        await shops.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$eq(this.shopId),
            ),
          ),
        )
      ).find(() => true) ?? null;
  }
  private changeType(val: string) {
    this.type = val;
  }
  //拼接图片
  private getImg(url: string) {
    return this.downloadUrl + url;
  }
  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  private backBtn() {
    this.$router.push(
      '/application/' + this.$route.params.applicationId + '/mall/index',
    );
  }
}
