
import {Component, Vue, Watch} from 'vue-property-decorator';
import {Form} from 'element-ui';
import {uploadImg} from '../../../../api/publicMethod';
import {productLabels, products} from '../../../../resources';
import {ProductLabel} from '@/externals/MaxCI-ProductLabel-v1';
import {ProductAttribute, ProductSKU} from '@/externals/MaxCI-Product-v1';
import {ObjectId} from 'bson';
import attrComp from '../attrComp/index.vue';
import draggable from 'vuedraggable';
import {AxiosError} from 'axios';
import axios from 'axios';
import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver/theme';
import vueTinymce from '@tinymce/tinymce-vue';
import 'tinymce/plugins/image';
import 'tinymce/plugins/batchUploadImage/plugin.min.js';
import 'tinymce/plugins/link';
import 'tinymce/plugins/code';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/contextmenu';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/colorpicker';
import 'tinymce/plugins/textcolor';
import 'tinymce/icons/default/icons.min.js';

interface ProductValue extends ProductLabel {
  values: Array<{
    name: string;
    checked: boolean;
  }>;
  name: string;
}
interface Sku {
  name: string;
  price: null | number;
  costPrice: null | number;
  stock: null | number;
  disabled: boolean;
}
const sortPrefix = '分类:';
@Component({
  name: 'editGoods',
  components: {attrComp, vueTinymce, draggable},
})
export default class extends Vue {
  private validprice = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (!/(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(value)) {
      callback(new Error('价格格式有误'));
    } else {
      callback();
    }
  };
  private validCommissionRate = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (!/^(1|(0(.\d+)?))$/.test(value)) {
      callback(new Error('请输入0-1之间的数值'));
    } else {
      callback();
    }
  };
  private validScore = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (value) {
      if (!/^([1-9]\d*|[0]{1,1})$/.test(value)) {
        callback(new Error('商品评分必须为正整数或0'));
      } else {
        callback();
      }
    } else {
      callback();
    }
  };
  private validName = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (value) {
      if (value.length > 32) {
        callback(new Error('商品名称必须小于32个字符'));
      } else {
        callback();
      }
    } else {
      callback();
    }
  };
  private validVirtualSales = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (value) {
      if (!/^([1-9]\d*|[0]{1,1})$/.test(value)) {
        callback(new Error('虚拟销量必须为正整数或0'));
      } else {
        callback();
      }
    } else {
      callback();
    }
  };
  private sortIndex = this.$route.query.sortIndex ?? '';
  private sortName = this.$route.query.sortName ?? '';
  private detailId = ObjectId.createFromHexString(
    this.$route.query.detailId as string,
  );
  private specType = '0';
  private dialogLabel = false; //选择标签弹窗
  private activeLabel = [0];
  private labelData: Array<ProductValue> = []; //标签数据
  private submitFlag = false; //提交开关
  private formData = {
    commissionShow: false, //是否分佣
    commissionRate: null as null | number, //分佣比例
    member: false, //是否是会员商品
    requiredOther: false, //单点不送
    name: '', //名称
    packingFee: 0, //包装费
    images: [] as Array<string>,
    // sales: '' as string | number, //销量
    description: '',
    purchaseQuantity: {min: 0, max: 999}, //最小,大购买数量
    labels: [] as Array<string>, //标签
    attributes: [] as Array<ProductAttribute>, //属性
    skus: [
      {
        name: '默认',
        price: null,
        costPrice: null,
        stock: null,
        disabled: true,
      },
    ] as Array<Sku>, //商品库存单位
    specs: [] as Array<ProductAttribute>, //商品规格
    shopId: ObjectId.createFromHexString(this.$route.params.shopId),
    applicationId: ObjectId.createFromHexString(
      this.$route.params.applicationId,
    ) as ObjectId,
    score: '', //商品评分
    virtualSales: '', //虚拟销量
    sort: '', //分类
  };
  private rules = {
    sort: [{required: true, message: '请选择分类', trigger: 'blur'}],
    name: [
      {required: true, message: '请输入标签名称', trigger: 'blur'},
      {validator: this.validName, trigger: 'blur'},
    ],
    images: [{required: true, message: '请上传商品图片', trigger: 'blur'}],
    packingFee: [
      {required: true, message: '请输入包装费用', trigger: 'blur'},
      {validator: this.validprice, trigger: 'blur'},
    ],
    commissionRate: [
      {required: true, message: '请输入分佣比例', trigger: 'blur'},
      {validator: this.validCommissionRate, trigger: 'blur'},
    ],
    score: [{validator: this.validScore, trigger: 'blur'}],
    virtualSales: [{validator: this.validVirtualSales, trigger: 'blur'}],
  };
  private action = '';
  private imgData = {};
  private skuShowMore = false;
  private firstLoad = false; //首次加载sku数据
  // 富文本框init配置
  private init = {
    language_url: '/tinymce/langs/zh_CN.js',
    language: 'zh_CN',
    skin_url: '/tinymce/skins/ui/oxide',
    height: 400,
    plugins:
      'link lists image batchUploadImage code table colorpicker textcolor wordcount contextmenu',
    toolbar:
      'bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote | undo redo | link unlink image batchUploadImage code | removeformat',
    branding: false,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    images_upload_handler: async (blobInfo: any, success: any) => {
      const file = blobInfo.blob();
      const res = await uploadImg(
        this.platformConfig.channels[0].channelId,
        this.platformConfig.applicationId,
        false,
        [
          {
            type: '缩放',
            mode: 'mfit',
            width: 900,
          },
        ],
      );
      const formData = new FormData();
      for (const key in res?.status?.upload?.data) {
        formData.append(key, res?.status?.upload?.data[key]);
      }
      formData.append('file', file);
      if (res?.status?.upload?.url) {
        const urlRes = await axios.post(res?.status?.upload?.url, formData);
        success(`${this.platformConfig.downloadUrl}${urlRes.data.file}`);
      }
    },
  };
  private productSortData: Array<string> = [];

  async created() {
    //查询分类
    const productSort = (
      await productLabels.find(stage =>
        stage.$match(match =>
          match(
            f => f('spec')('name'),
            e => e.$eq('分类'),
          )(
            f => f('spec')('shopId'),
            e => e.$eq(this.formData.shopId),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.formData.applicationId),
          ),
        ),
      )
    ).find(() => true);
    if (productSort) {
      this.productSortData = productSort.spec.values;
    }

    //查询标签数据
    try {
      const list = (await productLabels.find(stage =>
        stage.$match(match =>
          match(
            f => f('spec')('applicationId'),
            e => e.$eq(this.formData.applicationId),
          )(
            f => f('spec')('name'),
            e => e.$regex(new RegExp('商品标签:')),
          ),
        ),
      )) as Array<ProductValue>;
      list.forEach(item => {
        item.values = [];
        item.name = item.spec.name.slice(5, item.spec.name.length);
        if (item.values) {
          item.spec.values.forEach(valueItem => {
            item.values.push({
              name: valueItem,
              checked: false,
            });
          });
        }
      });
      this.labelData = list;

      //查询商品详情
      const productList = (
        await products.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$eq(this.detailId),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(this.formData.applicationId),
            )(
              f => f('spec')('shopId'),
              e => e.$eq(this.formData.shopId),
            ),
          ),
        )
      ).find(() => true);
      if (productList) {
        this.formData.name = productList.spec.name;
        this.formData.score = productList.spec.score
          ? productList.spec.score.toString()
          : '';
        this.formData.virtualSales = productList.spec.virtualSales
          ? productList.spec.virtualSales.toString()
          : '';
        this.formData.member = productList.spec.member;
        this.formData.requiredOther = productList.spec.requiredOther ?? false;
        this.formData.packingFee = productList.spec.packingFee / 100;
        // this.formData.sales = productList.spec.sales ?? '';
        this.formData.images = productList.spec.images;
        this.formData.description = productList.spec.description ?? '';
        this.formData.purchaseQuantity.min =
          productList.spec.purchaseQuantity?.min ?? 1;
        this.formData.purchaseQuantity.max =
          productList.spec.purchaseQuantity?.max ?? 999;
        const labels = [] as Array<string>;
        productList.spec.labels.forEach(item => {
          if (item.indexOf('分类:') > -1) {
            const sort = item.split(':')[1];
            if (this.productSortData.indexOf(sort) === -1) {
              this.formData.sort = '';
            } else {
              this.formData.sort = item.split(':')[1];
            }
          }
          if (item.indexOf(sortPrefix) === -1) {
            labels.push(item);
          }
        });
        this.formData.labels = labels;
        this.formData.labels.forEach(item => {
          const itemData = item.split(':');
          this.labelData.forEach(labelItem => {
            if (`${itemData[0]}:${itemData[1]}` === labelItem.spec.name) {
              labelItem.values.forEach(valueItem => {
                if (itemData[2] === valueItem.name) {
                  valueItem.checked = true;
                }
              });
            }
          });
        });
        this.formData.attributes = productList.spec.attributes;
        this.formData.specs = productList.spec.specs;
        this.specType = this.formData.specs.length === 0 ? '0' : '1';
        this.firstLoad = this.specType === '0' ? true : false;
        if (
          !productList.spec.commissionRate ||
          productList.spec.commissionRate === 0
        ) {
          this.formData.commissionShow = false;
        } else {
          this.formData.commissionShow = true;
          this.formData.commissionRate =
            productList.spec.commissionRate ?? null;
        }
        //回显sku
        if (this.formData.specs.length > 0) {
          const specArr = this.formData.specs.map(v => {
            return v.values;
          });

          let skuArr = this.cartesianProductOf(specArr) as [
            string,
            ...string[],
          ][];
          if (skuArr.length === 1 && skuArr[0].length === 0) {
            skuArr = [];
          }
          const skusAll = skuArr.map(v => {
            return {
              name: v.join('/'),
              price: null,
              stock: null,
              disabled: false,
            };
          }) as Array<Sku>;
          for (let i = 0; i < skusAll.length; i++) {
            for (let j = 0; j < productList.spec.skus.length; j++) {
              if (skusAll[i].name === productList.spec.skus[j].name) {
                const costPrice = productList.spec.skus[j].costPrice;
                skusAll[i].price = productList.spec.skus[j].price / 100;
                skusAll[i].costPrice = costPrice ? costPrice / 100 : null;
                skusAll[i].stock = productList.spec.skus[j].stock;
                skusAll[i].disabled = true;
              }
            }
          }
          skusAll.forEach((item, index) => {
            const sku = item;
            if (!item.disabled) {
              skusAll.splice(index, 1);
              skusAll.push(sku);
            }
          });
          this.formData.skus = skusAll;
        } else {
          this.formData.skus = [
            {
              name: '默认',
              price: productList.spec.skus[0].price / 100,
              costPrice: productList.spec.skus[0].costPrice
                ? productList.spec.skus[0].costPrice / 100
                : null,
              stock: productList.spec.skus[0].stock,
              disabled: true,
            },
          ];
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  mounted() {
    tinymce.init({});
  }
  //平台配置信息
  get platformConfig() {
    return this.$store.state.pConfig;
  }
  get skuLen() {
    let num = 0;
    this.formData.skus.forEach(item => {
      if (item.disabled) {
        num++;
      }
    });
    return num;
  }
  //生成sku
  @Watch('formData.specs', {deep: true})
  private changeSpecs() {
    if (this.specType === '1') {
      if (this.firstLoad) {
        const specArr = this.formData.specs.map(v => {
          return v.values;
        });
        //生新的sku数组
        let skuArr = this.cartesianProductOf(specArr) as [
          string,
          ...string[],
        ][];
        if (skuArr.length === 1 && skuArr[0].length === 0) {
          skuArr = [];
        }
        this.formData.skus = skuArr.map(v => {
          return {
            name: v.join('/'),
            price: null,
            costPrice: null,
            stock: null,
            disabled: true,
          };
        });
      } else {
        this.firstLoad = true;
      }
    }
  }
  //显示条数
  get skuShowLength() {
    let length = 0;
    if (this.skuShowMore) {
      length = this.formData.skus.length;
    } else {
      this.formData.skus.forEach(item => {
        if (item.disabled) {
          length++;
        }
      });
    }
    return length;
  }
  //是否开启
  private changeSkuUsed(index: number, e: boolean) {
    if (!e) {
      this.skuShowMore = true;
      const value = this.formData.skus[index];
      this.formData.skus.splice(index, 1);
      this.formData.skus.push(value);
    }
    if (e) {
      const value = this.formData.skus[index];
      this.formData.skus.splice(index, 1);
      this.formData.skus.unshift(value);
    }
  }
  //sku 排列组合
  private cartesianProductOf(arr: [string, ...string[]][]) {
    return Array.prototype.reduce.call(
      arr,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (a: any, b: any) => {
        const ret = [] as Array<[]>;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        a.forEach((a: any) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          b.forEach(function (b: any) {
            ret.push(a.concat([b]));
          });
        });
        return ret;
      },
      [[]],
    );
  }
  //是否有规格切换
  private switchSpec() {
    if (this.specType === '0') {
      this.formData.specs = [];
      this.formData.skus = [
        {
          name: '默认',
          price: null,
          costPrice: null,
          stock: null,
          disabled: true,
        },
      ];
    } else {
      this.formData.skus = [];
    }
  }
  //修改属性、规格
  private changeSpec(type: string, list: Array<ProductAttribute>) {
    const value = type === '属性' ? 'attributes' : 'specs';
    this.formData[value] = list.map(v => {
      return {
        name: v.name,
        values: v.values,
      };
    });
  }
  //选择标签保存
  private labelSumbit() {
    const labels = [] as Array<string>;
    this.labelData.forEach(item => {
      item.values.forEach(valueItem => {
        if (valueItem.checked) {
          labels.push(item.spec.name + ':' + valueItem.name);
        }
      });
    });
    this.formData.labels = labels;
    this.dialogLabel = false;
  }
  //处理标签数据
  private getLabel(label: string, type: string) {
    let value = '';
    if (type === 'name') {
      value = label.split(':')[1];
    } else {
      value = label.split(':')[2];
    }
    return value;
  }
  //选择标签
  private changeLabel(index: number, indexValue: number) {
    if (!this.labelData[index].spec.multiSelect) {
      this.labelData[index].values.forEach((item, labelIndex) => {
        if (labelIndex !== indexValue) {
          item.checked = false;
        }
      });
    }
    this.labelData[index].values[indexValue].checked =
      !this.labelData[index].values[indexValue].checked;
  }
  //保存
  private async submitForm() {
    (this.$refs.formData as Form).validate(async valid => {
      if (valid) {
        try {
          //购买数量
          if (
            !/^([0]|[1-9][0-9]*)$/.test(
              (this.formData.purchaseQuantity.min
                ? this.formData.purchaseQuantity.min
                : ''
              ).toString(),
            ) ||
            this.formData.purchaseQuantity.min === 0
          ) {
            this.$message.error('最小购买数量必须为大于等于1正整数');
            return;
          }
          if (
            !/^([0]|[1-9][0-9]*)$/.test(
              (this.formData.purchaseQuantity.max
                ? this.formData.purchaseQuantity.max
                : ''
              ).toString(),
            ) ||
            this.formData.purchaseQuantity.max === 0
          ) {
            this.$message.error('最大购买数量必须为大于等于1正整数');
            return;
          }
          // if (
          //   this.formData.sales !== '' &&
          //   !/^([0]|[1-9][0-9]*)$/.test(this.formData.sales.toString())
          // ) {
          //   this.$message.error('销量必须为正整数');
          //   return;
          // }
          if (this.specType === '1' && this.formData.specs.length === 0) {
            this.$message.error('请填写商品规格');
            return;
          }
          let priceFlag = true;
          let stockFlag = true;
          let costPriceFlag = true;
          this.formData.skus.forEach(item => {
            if (item.disabled) {
              if (
                !/(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(
                  (item.price ? item.price : '').toString(),
                )
              ) {
                priceFlag = false;
              }
            } else {
              if (
                item.price &&
                !/(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(
                  (item.price ? item.price : '').toString(),
                )
              ) {
                priceFlag = false;
              }
            }
            if (
              item.stock &&
              !/^([0]|[1-9][0-9]*)$/.test(
                (item.stock ? item.stock : '').toString(),
              )
            ) {
              stockFlag = false;
            }
            if (
              item.costPrice &&
              !/(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(
                (item.costPrice ? item.costPrice : '').toString(),
              )
            ) {
              costPriceFlag = false;
            }
          });
          if (!priceFlag) {
            this.$message.error('规格价格格式不正确');
            return;
          }
          if (!stockFlag) {
            this.$message.error('规格库存必须为正整数');
            return;
          }
          if (!costPriceFlag) {
            this.$message.error('规格成本价格式不正确');
            return;
          }
          //商品标签
          const labels = this.formData.labels.map(v => {
            return v;
          }) as Array<string>;
          labels.push('分类:' + this.formData.sort);

          //商品属性
          const attributes = [] as Array<ProductAttribute>;
          this.formData.attributes.forEach(item => {
            if (item.name && item.values.length > 0) {
              attributes.push(item);
            }
          });

          //商品规格
          const specs = [] as Array<ProductAttribute>;
          this.formData.specs.forEach(item => {
            if (item.name && item.values.length > 0) {
              specs.push(item);
            }
          });
          const skus = [] as Array<ProductSKU>;
          this.formData.skus.forEach(item => {
            if (item.disabled) {
              let stock = 0;
              if (!item.stock) {
                stock = 9999999;
              } else {
                stock = Number(item.stock);
              }
              skus.push({
                name: item.name ? item.name : '默认',
                stock: stock,
                ...(item.costPrice
                  ? {costPrice: Math.round(Number(item.costPrice) * 100)}
                  : {}),
                price: Math.round(Number(item.price) * 100),
              });
            }
          });
          this.submitFlag = true;
          const product = await products.update(
            filter =>
              filter(
                f => f('_id'),
                e => e.$eq(this.detailId),
              )(
                f => f('spec')('shopId'),
                e => e.$eq(this.formData.shopId),
              )(
                f => f('spec')('applicationId'),
                e => e.$eq(this.formData.applicationId),
              ),
            update => {
              update.$set(s => {
                s(f => f('spec')('name'), this.formData.name)(
                  f => f('spec')('member'),
                  this.formData.member,
                )(f => f('spec')('requiredOther'), this.formData.requiredOther)(
                  f => f('spec')('images'),
                  this.formData.images as
                    | []
                    | [string]
                    | [string, string]
                    | [string, string, string]
                    | [string, string, string, string],
                )(f => f('spec')('specs'), specs)(
                  f => f('spec')('attributes'),
                  attributes,
                )(
                  f => f('spec')('skus'),
                  skus as [ProductSKU, ...ProductSKU[]],
                )(
                  f => f('spec')('packingFee'),
                  Math.round(Number(this.formData.packingFee) * 100),
                )(f => f('spec')('purchaseQuantity'), {
                  min: this.formData.purchaseQuantity.min
                    ? this.formData.purchaseQuantity.min
                    : 0,
                  max: this.formData.purchaseQuantity.max
                    ? this.formData.purchaseQuantity.max
                    : 0,
                })(f => f('spec')('labels'), labels)(
                  f => f('spec')('description'),
                  this.formData.description,
                )(
                  f => f('spec')('commissionRate'),
                  this.formData.commissionShow
                    ? Number(this.formData.commissionRate)
                    : 0,
                );
                if (this.formData.score !== '') {
                  s(f => f('spec')('score'), Number(this.formData.score));
                }
                if (this.formData.virtualSales !== '') {
                  s(
                    f => f('spec')('virtualSales'),
                    Number(this.formData.virtualSales),
                  );
                }
                return s;
              });
              if (this.formData.score === '') {
                update.$unset(s => {
                  s(f => f('spec')('score'), '');
                  return s;
                });
              }
              if (this.formData.virtualSales === '') {
                update.$unset(s => {
                  s(f => f('spec')('virtualSales'), '');
                  return s;
                });
              }
              return update;
            },
          );
          if (product.length > 0) {
            if (this.formData.sort !== this.sortName) {
              await productLabels.update(
                filter =>
                  filter(
                    f => f('spec')('name'),
                    e => e.$eq(`分类:${this.sortName}:排序`),
                  )(
                    f => f('spec')('shopId'),
                    e => e.$eq(this.formData.shopId),
                  )(
                    f => f('spec')('applicationId'),
                    e => e.$eq(this.formData.applicationId),
                  ),
                update =>
                  update.$pull(p =>
                    p(
                      f => f('spec')('values'),
                      'value',
                      this.detailId.toHexString(),
                    ),
                  ),
              );
              await productLabels.update(
                filter =>
                  filter(
                    f => f('spec')('name'),
                    e => e.$eq(`分类:${this.formData.sort}:排序`),
                  )(
                    f => f('spec')('shopId'),
                    e => e.$eq(this.formData.shopId),
                  )(
                    f => f('spec')('applicationId'),
                    e => e.$eq(this.formData.applicationId),
                  ),
                update =>
                  update.$push(p =>
                    p(f => f('spec')('values'), this.detailId.toHexString()),
                  ),
              );
            }

            this.$message.success('保存成功');
            this.back();
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          if (e.isAxiosError) {
            const axiosError = e as AxiosError;
            if (
              axiosError.response?.status === 500 &&
              (axiosError.response.data.name === 'MongoError' ||
                axiosError.response.data.name === 'Error') &&
              axiosError.response.data.message.indexOf('E11000') > -1
            ) {
              this.$message.error('名称不能重复');
            }
          }
        } finally {
          this.submitFlag = false;
        }
      } else {
        return false;
      }
    });
  }
  private back() {
    this.$emit('back', this.sortIndex);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async successImgList(value: {file: string}) {
    if (this.formData.images.length < 5) {
      this.formData.images.push(value.file);
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async removeImgList(value: string, file: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.formData.images = file.map((v: any) => {
      return v.response.file;
    });
  }
  get images() {
    return this.formData.images.map(v => {
      return {
        uid: v,
        url: this.platformConfig.downloadUrl + v,
      };
    });
  }
  //删除图片
  private imgClose(index: number) {
    this.formData.images.splice(index, 1);
    this.$forceUpdate();
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async getImgWH(file: any) {
    return new Promise(function (resolve) {
      var fileData = file;
      //读取图片数据
      var reader = new FileReader();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      reader.onload = (e: any) => {
        if (e) {
          var data = e.target.result;
          var image = new Image();
          image.onload = () => {
            resolve(image);
          };
          image.src = data;
        }
      };
      reader.readAsDataURL(fileData);
    });
  }
  //获取图片上传地址
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async beforeAvatarUpload(file: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let process = [] as any;
    const image = (await this.getImgWH(file)) as {
      width: number;
      height: number;
    };
    const wImg = image.width;
    const hImg = image.height;
    let w = 0,
      x = 0,
      y = 0;
    if (Number(wImg) > Number(hImg)) {
      w = Number(hImg);
      x = (Number(wImg) - Number(hImg)) / 2;
      y = 0;
    } else {
      w = Number(wImg);
      x = 0;
      y = (Number(hImg) - Number(wImg)) / 2;
    }
    if (w <= 900) {
      process = [
        {
          type: '裁剪',
          x: Math.round(x),
          y: Math.round(y),
          width: w,
          height: w,
        },
      ];
    } else {
      if (Number(wImg) > Number(hImg)) {
        process.push({
          type: '缩放',
          mode: 'fill',
          height: 900,
        });
      } else {
        process.push({
          type: '缩放',
          mode: 'fill',
          width: 900,
        });
      }
      if (Number(wImg) > Number(hImg)) {
        x = ((Number(wImg) * 900) / Number(hImg) - 900) / 2;
        y = 0;
      } else {
        x = 0;
        y = ((Number(hImg) * 900) / Number(wImg) - 900) / 2;
      }
      process.push({
        type: '裁剪',
        x: Math.round(x),
        y: Math.round(y),
        width: 900,
        height: 900,
      });
    }
    const isJPEG = file.type === 'image/jpeg';
    const isPNG = file.type === 'image/png';
    const isPG = isJPEG || isPNG; //限制图片格式为jpg / png
    const isLt1M = file.size / 1024 / 1024 < 10; //限制图片大小

    if (!isPG) {
      this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
      throw new Error();
    } else if (!isLt1M) {
      this.$message.error('上传图片大小不能超过 10MB!');
      throw new Error();
    } else {
      if (this.platformConfig) {
        const res = await uploadImg(
          this.platformConfig.channels[0].channelId,
          this.platformConfig.applicationId,
          false,
          process,
        );
        if (!res) {
          throw new Error();
        }
        this.action = res.status?.upload?.url ?? '';
        this.imgData = res.status?.upload?.data ?? {};
      } else {
        throw new Error();
      }
    }
  }
}
